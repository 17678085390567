import { useState, useEffect } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import pako from 'pako';
import { encode } from 'js-base64';

import './App.css';

// TODO: additional arguments
// const base64template = result => `echo ${result} | base64 -d | gunzip | bash -s <CHANGE_PORT_NUMBER>`

const base64Template = (result, command) => result && `echo ${result} | base64 -d | ${command}`
const gzipTemplate = (result, command) => result && `echo ${result} | base64 -d | gunzip | ${command}`


// eslint-disable-next-line no-undef
const guessLang = new GuessLang({ minContentSize: 0 });

const supportedLanguages = {
  "sh": {
    lang: "sh",
    command: "bash",
    syntax: "bash",
  },
  "js": {
    lang: "js",
    command: 'node',
    syntax: "javascript",
  },
  "py": {
    lang: "py",
    command: "python",
    syntax: "python",
  }
};


const startingCode = `echo "Hello World!"`;

function App() {
  const [code, setCode] = useState(startingCode);
  const [result, setResult] = useState('');
  const [lang, setLang] = useState(supportedLanguages['sh']);

  useEffect(() => {
    async function detectLanguage() {
      const guessedLanguages = await guessLang.runModel(`\n\n\n\n\n${code}\n\n\n\n\n\n`);
      console.log({ guessedLanguages, code });

      if (guessedLanguages.length === 0) return;

      const guessedLanguage = guessedLanguages.find(l => l.languageId in supportedLanguages)

      console.log({ 'guessedLanguage.languageId': guessedLanguage.languageId });
      console.log(supportedLanguages[guessedLanguage.languageId]);
      setLang(supportedLanguages[guessedLanguage.languageId]);
    }
    detectLanguage();
  }, [code])

  const handleChange = (e) => {
    let value = typeof e === 'string' ? e : e.target.value;
    setCode(value);



    const base64encoded = encode(value);

    // Convert binary string to character-number array
    const charData = value.split('').map(function (x) { return x.charCodeAt(0); });

    // Turn number array into byte-array
    const binData = new Uint8Array(charData);

    // gzip compression
    const data = pako.gzip(binData, { raw: true, to: 'string' });

    // base64 encode compressed data
    const base64encodedAndGziped = encode(data);

    if (base64encoded.length > base64encodedAndGziped.length + 8) {
      setResult(gzipTemplate(base64encodedAndGziped, lang.command));
    } else {
      setResult(base64Template(base64encoded, lang.command));
    }
  };

  // call handleChange on load
  useEffect(() => {
    handleChange(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const selectAllAndCopy = (e) => {
    const element = e.target;
    element.select();
    document.execCommand('copy');
  }

  return (
    <div className="App">
      <h2>Code</h2>
      <CodeEditor
        value={code}
        language={lang.syntax}
        placeholder="Please enter your code! 🤓"
        onChange={handleChange}
        padding={15}
        style={{
          fontSize: 24,
          backgroundColor: "#f5f5f5",
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
      <div>
        <h3>Language</h3>
        {Object.entries(supportedLanguages).map(([lng, { syntax }]) => (
          <label key={lng}>
            <input
              type="radio"
              name="language"
              value={lng}
              checked={lng === lang.lang}
              onChange={(e) => setLang(supportedLanguages[e.target.value])}
            />
            {syntax}
          </label>
        ))}
      </div>
      <h2>Result</h2>
      <CodeEditor
        value={result}
        language="shell"
        placeholder="Command will be displayed here and auto-copied to your clipboard on click! 🎉"
        onClick={selectAllAndCopy}
        // onChange={(evn) => setCode(evn.target.value)}
        padding={15}
        style={{
          fontSize: 24,
          backgroundColor: "#f5f5f5",
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
      {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(lang, null, 2)}</pre> */}
      {/* <textarea cols="30" rows="10" value={result} onClick={selectAllAndCopy}></textarea> */}
    </div >
  );
}

export default App;
